import {React, useCallback, useEffect, useMemo, useRef, useState} from 'react';

import upload from '../../assets/upload.svg';
import edit from '../../assets/edit.svg';
import del from '../../assets/delete.svg';
import plus from '../../assets/plus.svg';
import './courseRegistration.css';
import Modal from '../../modal/Modal';
import TextField from '../../components/textField/textField';
import {validator} from '../../utils/validator';

const CourseRegistration = () => {
  const [touchedFields] = useState({});
  const [modalActive, setModalActive] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [tasks, setTasks] = useState([]);

  const handleAddTask = (taskData) => {
    setTasks((prevTasks) => [...prevTasks, taskData]);
  };

  const handleDeleteTask = (index) => {
    setTasks((prevTasks) => prevTasks.filter((task, i) => i !== index));
  };
  const handleEditTask = (index) => {
    setEditIndex(index);
    setModalActive(true);
  };

  const handleSaveTask = (index, updatedTask) => {
    setTasks((prevTasks) =>
      prevTasks.map((task, i) =>
        (i === index ? {...task, ...updatedTask} : task)));
    setModalActive(false);
    setEditIndex(null);
  };

  const [assistants, setAssistants] = useState(['']);
  const [assistantInputValues, setAssistantInputValues] = useState(['']);
  const [showAssistantOptions, setShowAssistantOptions] = useState([]);
  const assistantDropdownRefs = useRef([]);

  const addAssistant = () => {
    setAssistants([...assistants, '']);
    setAssistantInputValues([...assistantInputValues, '']);
    setShowAssistantOptions([...showAssistantOptions, false]);
  };

  const removeAssistant = (index) => {
    if (assistants.length === 1) {
      setAssistants(['']);
      setAssistantInputValues(['']);
    } else {
      const updatedAssistants = assistants.filter((_, i) => i !== index);
      const updatedInputValues = assistantInputValues.filter((_, i) => i !== index);
      const updatedShowOptions = showAssistantOptions.filter((_, i) => i !== index);
      setAssistants(updatedAssistants);
      setAssistantInputValues(updatedInputValues);
      setShowAssistantOptions(updatedShowOptions);
    }
  };

  const handleAssistantClickOutside = (event) => {
    assistantDropdownRefs.current.forEach((dropdownRef, index) => {
      if (dropdownRef && !dropdownRef.contains(event.target)) {
        const updatedShowOptions = [...showAssistantOptions];
        updatedShowOptions[index] = false;
        setShowAssistantOptions(updatedShowOptions);
      }
    });
  };

  const handleAssistantInputChange = (index, event) => {
    const value = event.target.value;
    const updatedInputValues = [...assistantInputValues];
    updatedInputValues[index] = value;
    setAssistantInputValues(updatedInputValues);

    const updatedShowOptions = [...showAssistantOptions];
    updatedShowOptions[index] = true;
    setShowAssistantOptions(updatedShowOptions);
  };

  const handleAssistantOptionClick = (index, value) => {
    const updatedAssistants = [...assistants];
    updatedAssistants[index] = value;
    setAssistants(updatedAssistants);

    const updatedInputValues = [...assistantInputValues];
    updatedInputValues[index] = value;
    setAssistantInputValues(updatedInputValues);

    const updatedShowOptions = [...showAssistantOptions];
    updatedShowOptions[index] = false;
    setShowAssistantOptions(updatedShowOptions);
  };

  const [seminarists, setSeminarists] = useState(['']);
  const [inputValues, setInputValues] = useState(['']);
  const [showOptions, setShowOptions] = useState([]);
  const dropdownRefs = useRef([]);
  const allSeminarists = ['Семинарист 1', 'Семинарист 2', 'Семинарист 3'];
  const allAssistants = ['Ассистент 1', 'Ассистент 2', 'Ассистент 3'];
  const getAvailableSeminarists = (index) => {
    return allSeminarists.filter(
        ((seminarist) => !seminarists.includes(seminarist) || seminarists[index] === seminarist)
    );
  };

  const getAvailableAssistants = (index) => {
    return allAssistants.filter(
        ((assistant) => !assistants.includes(assistant) || assistants[index] === assistant)
    );
  };

  const handleSeminaristChange = (index, event) => {
    const value = event.target.value;
    const updatedInputValues = [...inputValues];
    updatedInputValues[index] = value;
    setInputValues(updatedInputValues);

    const updatedShowOptions = [...showOptions];
    updatedShowOptions[index] = true;
    setShowOptions(updatedShowOptions);
  };

  const handleOptionClick = (index, value) => {
    const updatedSeminarists = [...seminarists];
    updatedSeminarists[index] = value;
    setSeminarists(updatedSeminarists);

    const updatedInputValues = [...inputValues];
    updatedInputValues[index] = value;
    setInputValues(updatedInputValues);

    const updatedShowOptions = [...showOptions];
    updatedShowOptions[index] = false;
    setShowOptions(updatedShowOptions);
  };

  const addSeminarist = () => {
    setSeminarists([...seminarists, '']);
    setInputValues([...inputValues, '']);
    setShowOptions([...showOptions, false]);
  };

  const removeSeminarist = (index) => {
    if (seminarists.length === 1) {
      setSeminarists(['']);
      setInputValues(['']);
    } else {
      const updatedSeminarists = seminarists.filter((_, i) => i !== index);
      const updatedInputValues = inputValues.filter((_, i) => i !== index);
      const updatedShowOptions = showOptions.filter((_, i) => i !== index);
      setSeminarists(updatedSeminarists);
      setInputValues(updatedInputValues);
      setShowOptions(updatedShowOptions);
    }
  };

  const handleClickOutside = (event) => {
    dropdownRefs.current.forEach((dropdownRef, index) => {
      if (dropdownRef && !dropdownRef.contains(event.target)) {
        const updatedShowOptions = [...showOptions];
        updatedShowOptions[index] = false;
        setShowOptions(updatedShowOptions);
      }
    });
  };

  const canAddSeminarist = seminarists.every((s) => s !== '') &&
    seminarists.filter((s) => s !== '').length < allSeminarists.length;
  const canAddAssistant = assistants.every((a) => a !== '') &&
    assistants.filter((a) => a !== '').length < allAssistants.length;
  // Validation field
  const [data, setData] = useState({name: '', id: '', table: ''});
  const [errors, setErrors] = useState({});
  const handleChange = ({target}) => {
    const {name, value} = target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (value.trim() === '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: 'Это обязательное поле',
      }));
    } else {
      const fieldErrors = validateField(name, value);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: fieldErrors[name] || '',
      }));
    }
  };

  const validatorConfig = useMemo(() => ({
    name: {
      isRequired: {
        message: 'Название курса обязательно для заполнения',
      },
      isFormatValid: {
        message: 'Название курса должен содержать только ' +
          'буквы (кириллица и латиница ' +
          'верхнего и нижнего регистров), цифры, и ' +
          'символы -, :, _, +, . и иметь длину от 1 до 255 символов',
      },
    },
    id: {
      isRequired: {
        message: 'Идентификатор курса обязателен для заполнения',
      },
      isIdValid: {
        message: 'Идентификатор курса должен содержать только латинские ' +
          'символы верхнего и нижнего регистров, цифры, _, - и . и ' +
          'иметь длину от 1 до 100 символов',
      },
    },
    table: {
      isRequired: {
        message: 'Идентификатор курса обязателен для заполнения',
      },
      isTableValid: {
        message: 'Ссылка на таблицу должна начинаться с https://docs.google.com/spreadsheets/ ' +
          'и иметь длину не более 1024 символов',
      },
    },
    taskCount: {
      isRequired: {
        message: 'Поле обязательно для заполнения',
      },
      isNumberValid: {
        message: 'Количество задач должно быть числом',
      },
      isFormatValid: {
        message: 'Количество задач должно быть числом от 0 до 100',
      },
    },
    branchesWithoutTests: {
      isRequired: {
        message: 'Поле обязательно для заполнения',
      },
      isBranchNumberValid: {
        message: 'Номера веток должны быть положительными целыми числами, разделенными запятыми',
        taskCount: 100,
      },
    },
  }), []);

  const validate = useCallback((dataToValidate = data) => {
    const validationErrors = {};

    Object.keys(dataToValidate).forEach((field) => {
      if (dataToValidate[field].trim() === '') {
        validationErrors[field] = 'Это обязательное поле.';
      } else {
        const fieldErrors = validator({[field]: dataToValidate[field]}, validatorConfig);
        if (fieldErrors[field]) {
          validationErrors[field] = fieldErrors[field];
        }
      }
    });

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  }, [data, validatorConfig]);
  const allSupervisors = [
    'Колмогоров Александр Алексеевич, kolmogorov.aa@phystech.edu',
    'Фартыгин Артем, temikfart.aa@phystech.edu',
    'Суворов Алексей, kolmogorov.aa@phystech.edu',
    'Якушкова Елизавета, kolmogorov.aa@phystech.edu',
    'Кошелев Александр, kolmogorov.aa@phystech.edu',
  ];

  const [, setSupervisor] = useState('');
  const [supervisorInputValue, setSupervisorInputValue] = useState('');
  const [showSupervisorOptions, setShowSupervisorOptions] = useState(false);
  const dropdownSupervisorRef = useRef(null);
  const filteredSupervisors = allSupervisors.filter((option) =>
    option.toLowerCase().includes(supervisorInputValue.toLowerCase())
  );

  const handleSupervisorInputChange = (event) => {
    const value = event.target.value;
    setSupervisorInputValue(value);
    setShowSupervisorOptions(true);
  };

  const handleSupervisorOptionClick = (option) => {
    const [name, email] = option.split(', ');
    const formattedOption = `${name}\n${email}`;
    setSupervisor(formattedOption);
    setSupervisorInputValue(formattedOption);
    setShowSupervisorOptions(false);
  };

  const handleSupervisorsClickOutside = (event) => {
    if (dropdownSupervisorRef.current && !dropdownSupervisorRef.current.contains(event.target)) {
      setShowSupervisorOptions(false);
    }
  };

  // Validation checkbox
  const [serversTouched, setServersTouched] = useState(false);
  const [serversError, setServersError] = useState('');
  useEffect(() => {
    const handleMousedown = (event) => {
      if (showAssistantOptions) {
        handleAssistantClickOutside(event);
      }
      if (showOptions) {
        handleClickOutside(event);
      }
      if (showSupervisorOptions) {
        handleSupervisorsClickOutside(event);
      }
    };

    document.addEventListener('mousedown', handleMousedown);

    return () => {
      document.removeEventListener('mousedown', handleMousedown);
    };
    // eslint-disable-next-line
  }, [showAssistantOptions, showOptions, showSupervisorOptions]);
  const handleCheckboxChange = () => {
    setServersTouched(true);
    const isChecked = checkIfAtLeastOneChecked();
    if (!isChecked) {
      setServersError('Выберите хотя бы один сервер');
    } else {
      setServersError('');
    }
  };
  const checkIfAtLeastOneChecked = () => {
    const checkboxes = document.querySelectorAll('.server-list input[type="checkbox"]');
    return Array.from(checkboxes).some((checkbox) => checkbox.checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = validate();
    const isServersChecked = checkIfAtLeastOneChecked();
    if (!isServersChecked) {
      setServersError('Выберите хотя бы один сервер.');
    }
    if (!isValid || !isServersChecked) {
      return;
    }
    window.location.href = '/courses/register/success';
  };


  const handleBlur = ({target: {name, value}}) => {
    if (value.trim() === '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: 'Это обязательное поле.',
      }));
    } else if (errors[name]) {
      const fieldErrors = validateField(name, value);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: fieldErrors[name] || '',
      }));
    }
  };

  useEffect(() => {
    if (Object.keys(touchedFields).length > 0) {
      validate();
    }
  }, [data, touchedFields, validate]);

  const validateField = (name, value) => {
    return validator({[name]: value}, validatorConfig);
  };
  return (
    <div className="registration" id="registration">
      <div className="container">
        <div className="register-form-container">
          <h1 className="form-title">
            Регистрация курса
          </h1>
          <form onSubmit={handleSubmit} className="form-filds">
            <div className="form-field">
              <div className="d-flex align-items-center">
                <h4>Полное название курса</h4>
                <span className="form-star">*</span>
              </div>
              <TextField
                type="text"
                id="name"
                name="name"
                value={data.name}
                onChange={handleChange}
                placeholder="Параллельные алгоритмы"
                error={errors.name}
                onBlur={handleBlur}
              />
            </div>
            <div className="form-field">
              <div className="d-flex align-items-center">
                <h4>Идентификатор курса</h4>
                <span className="form-star">*</span>
              </div>
              <TextField
                type="text"
                id="id"
                name="id"
                value={data.id}
                onChange={handleChange}
                placeholder="my-iDENTifier.123"
                error={errors.id}
                onBlur={handleBlur}
              />
            </div>
            <div className="form-field">
              <div className="d-flex align-items-center">
                <h4>Руководитель курса</h4>
                <span className="form-star">*</span>
              </div>
            </div>
            <div className="dropdown" ref={dropdownSupervisorRef}>
              <textarea
                value={supervisorInputValue}
                onClick={() => setShowSupervisorOptions(true)}
                onChange={handleSupervisorInputChange}
                className="form-input form-select form-visitor"
                placeholder="Колмогоров Александр Алексеевич, kolmogorov.aa@phystech.edu"
                style={{whiteSpace: 'pre-line'}}
              />
              {showSupervisorOptions && (
                <div className="dropdown-menu">
                  {filteredSupervisors.length > 0 ? (
                    filteredSupervisors.map((option, index) => {
                      const [name, email] = option.split(', ');
                      return (
                        <div
                          key={index}
                          className="dropdown-item"
                          onClick={() => handleSupervisorOptionClick(option)}
                        >
                          <span>{name}</span>
                          <span style={{display: 'block', color: 'gray', fontSize: '12px'}}>{email}</span>
                        </div>
                      );
                    })
                  ) : (
                    <div className="dropdown-item">Ничего не найдено</div>
                  )}
                </div>
              )}
            </div>
            <div className="form-field">
              <div className="d-flex align-items-center">
                <h4>Семинаристы курса</h4>
              </div>
              {seminarists.map((seminarist, index) => {
                const filteredOptions = getAvailableSeminarists(index).filter((option) =>
                  option.toLowerCase().includes(inputValues[index].toLowerCase())
                );

                return (
                  <div key={index} className="d-flex align-items-center">
                    <div className="dropdown" ref={(el) => (dropdownRefs.current[index] = el)}>
                      <input
                        type="text"
                        value={inputValues[index]}
                        onClick={() => setShowOptions((prev) => {
                          const updated = [...prev];
                          updated[index] = true;
                          return updated;
                        })}
                        onChange={(e) => handleSeminaristChange(index, e)}
                        className="form-input form-select"
                        placeholder="Начните вводить"
                      />
                      {showOptions[index] && (
                        <div className="dropdown-menu">
                          {filteredOptions.length > 0 ? (
                            filteredOptions.map((option, i) => (
                              <div
                                key={i}
                                className="dropdown-item"
                                onClick={() => handleOptionClick(index, option)}
                              >
                                {option}
                              </div>
                            ))
                          ) : (
                            <div className="dropdown-item">Ничего не найдено</div>
                          )}
                        </div>
                      )}
                    </div>
                    {(seminarists.length > 1 || seminarist) && (
                      <button
                        type="button"
                        className="delete name-delete"
                        onClick={() => removeSeminarist(index)}
                      >
                        <img src={del} alt="Удалить" />
                      </button>
                    )}
                  </div>
                );
              })}
              <button
                type="button"
                className="form-plus"
                onClick={addSeminarist}
                disabled={!canAddSeminarist}
              >
                <img src={plus} alt="Plus"/>
              </button>
            </div>
            <div className="form-field">
              <div className="d-flex align-items-center">
                <h4>Ассистенты курса</h4>
              </div>
              {assistants.map((assistant, index) => {
                const filteredOptions = getAvailableAssistants(index).filter((option) =>
                  option.toLowerCase().includes(assistantInputValues[index].toLowerCase())
                );

                return (
                  <div key={index} className="d-flex align-items-center">
                    <div className="dropdown" ref={(el) => (assistantDropdownRefs.current[index] = el)}>
                      <input
                        type="text"
                        value={assistantInputValues[index]}
                        onClick={() => setShowAssistantOptions((prev) => {
                          const updated = [...prev];
                          updated[index] = true;
                          return updated;
                        })}
                        onChange={(e) => handleAssistantInputChange(index, e)}
                        className="form-input form-select"
                        placeholder="Начните вводить ФИО или email"
                      />
                      {showAssistantOptions[index] && (
                        <div className="dropdown-menu">
                          {filteredOptions.length > 0 ? (
                            filteredOptions.map((option, i) => (
                              <div
                                key={i}
                                className="dropdown-item"
                                onClick={() => handleAssistantOptionClick(index, option)}
                              >
                                {option}
                              </div>
                            ))
                          ) : (
                            <div className="dropdown-item">Ничего не найдено</div>
                          )}
                        </div>
                      )}
                    </div>
                    {(assistants.length > 1 || assistant) && (
                      <button
                        type="button"
                        className="delete name-delete"
                        onClick={() => removeAssistant(index)}
                      >
                        <img src={del} alt="Удалить" />
                      </button>
                    )}
                  </div>
                );
              })}
              <button
                type="button"
                className="form-plus"
                onClick={addAssistant}
                disabled={!canAddAssistant}
              >
                <img src={plus} alt="Plus"/>
              </button>
            </div>
            <div className="form-field">
              <div className="d-flex align-items-center">
                <h4>Ссылка на таблицу курса</h4>
                <span className="form-star">*</span>
              </div>
              <div className="form-indent">
                <p>В таблице должно быть минимум 2 вкладки:</p>
                <ul>
                  <li>Список студентов</li>
                  <li>Оценки студентов</li>
                </ul>
                <p>К таблице нужно выдать доступ на 2 email'а:</p>
                <ul>
                  <li>servers@atp-fivt.org</li>
                  <li>gsheets@automation-robot-1.iam.gserviceaccount.com</li>
                </ul>
              </div>
              <TextField
                type="text"
                id="table"
                name="table"
                value={data.table}
                onChange={handleChange}
                placeholder="https://docs.google.com/spreadsheets/d/example"
                error={errors.table}
                onBlur={handleBlur}
              />
            </div>
            <div className="form-field">
              <div className="d-flex align-items-center">
                <h4>Конфигурирование домашних заданий</h4>
                <span className="form-star">*</span>
              </div>
              {tasks.length >= 0 && (
                <div className="table-container">
                  <table>
                    <thead>
                      <tr>
                        <th>Название</th>
                        <th>Количество задач</th>
                        <th>Тестирование</th>
                        <th>№ веток без тестов</th>
                        <th>Действия</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tasks.map((task, index) => (
                        <tr key={index}>
                          <td>{task.supervisor}</td>
                          <td>{task.unknownTaskCount ? 'N/A' :
                            task.taskCount}</td>
                          <td>{task.systemTest ? 'Да' : 'Нет'}</td>
                          <td>{task.branchesWithoutTests}</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <button type="button" className="edit"
                                onClick={() => handleEditTask(index)}>
                                <img src={edit} alt="Редактировать"/>
                              </button>
                              <button type="button" className="delete"
                                onClick={() => handleDeleteTask(index)}>
                                <img src={del} alt="Удалить"/>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
              <button type="button" className="form-plus form-modal"
                onClick={() => setModalActive(true)}>
                <img src={plus} alt="Plus"/>
              </button>
              <Modal
                active={modalActive}
                setActive={setModalActive}
                onAdd={handleAddTask}
                onSave={(taskData) => {
                  if (editIndex !== null) {
                    handleSaveTask(editIndex, taskData);
                  } else {
                    handleAddTask(taskData);
                  }
                }}
                taskToEdit={editIndex !== null ? tasks[editIndex] : null}
              />
            </div>
            <div className="form-field form-margin">
              <div className="d-flex align-items-center">
                <h4>Необходимые сервера</h4>
                <span className="form-star">*</span>
              </div>
              <p> Отметьте галочкой нужные для курса сервера.
                Характеристики каждого<br/> сервера - в кружочке с вопросом.
              </p>
              <ul className="server-list">
                <li className="server-item d-flex align-items-center">
                  <label className="server-name">
                    <input type="checkbox" onChange={handleCheckboxChange}/>
                  </label>
                  <p>servername</p>
                  <span
                    className=
                      {modalActive ? 'server-characteristics none' :
                        'server-characteristics'}
                    title="Здесь описание для подсказки">?
                  </span>
                </li>
                <li className="server-item d-flex align-items-center">
                  <label className="server-name">
                    <input type="checkbox" onChange={handleCheckboxChange}/>
                  </label>
                  <p>servername</p>
                  <span
                    className=
                      {modalActive ? 'server-characteristics none' :
                        'server-characteristics'}
                    title="Здесь описание для подсказки">?
                  </span>
                </li>
                <li className="server-item d-flex align-items-center">
                  <label className="server-name">
                    <input type="checkbox" onChange={handleCheckboxChange}/>
                  </label>
                  <p>servername</p>
                  <span
                    className=
                      {modalActive ? 'server-characteristics none' :
                        'server-characteristics'}
                    title="Здесь описание для подсказки">?
                  </span>
                </li>
                <li className="server-item d-flex align-items-center">
                  <label className="server-name">
                    <input type="checkbox" onChange={handleCheckboxChange}/>
                  </label>
                  <p>servername</p>
                  <span
                    className=
                      {modalActive ? 'server-characteristics none' :
                        'server-characteristics'}
                    title="Здесь описание для подсказки">?
                  </span>
                </li>
                <li className="server-item d-flex align-items-center">
                  <label className="server-name">
                    <input type="checkbox" onChange={handleCheckboxChange}/>
                  </label>
                  <p>servername</p>
                  <span
                    className=
                      {modalActive ? 'server-characteristics none' :
                        'server-characteristics'}
                    title="Здесь описание для подсказки">?
                  </span>
                </li>
              </ul>
              {serversTouched && serversError && <p className="form-error">{serversError}</p>}
            </div>
            <div className="form-field">
              <div>
                <h4>Специализированный CI и Dockerfile</h4>
              </div>
              <div className="form-indent">
                <p>Прикрепите архив из следующих файлов:</p>
                <ul className="no-flex">
                  <li>Если нужен CI, то добавьте gitlab-ci.yml файлы для
                    каждого<br/> репозитория;
                  </li>
                  <li>Если нужен специальный Docker контейнер, то добавьте
                    исходный<br/> Dockerfile с нужными данными.
                  </li>
                </ul>
              </div>
              <button type="button" className="form-plus">
                <img src={upload} alt="Upload img"/>
              </button>
            </div>
            <button type="submit" className="form-register">
              Зарегистрировать курс
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CourseRegistration;
