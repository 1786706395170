import React, {useState, useEffect} from 'react';
import './modal.css';

const Modal = ({active, setActive, onSave, taskToEdit}) => {
  const [data, setData] = useState({
    supervisor: '',
    taskCount: '',
    unknownTaskCount: false,
    systemTest: false,
    branchesWithoutTests: '',
  });

  const [nameError, setNameError] = useState('');
  const [taskCountError, setTaskCountError] = useState('');
  const [branchesError, setBranchesError] = useState('');

  useEffect(() => {
    if (taskToEdit) {
      setData(taskToEdit);
    } else {
      setData({
        supervisor: '',
        taskCount: '',
        unknownTaskCount: false,
        systemTest: false,
        branchesWithoutTests: '',
      });
    }
    if (active) {
      setNameError('');
      setTaskCountError('');
      setBranchesError('');
    }
  }, [taskToEdit, active]);

  const handleChange = (e) => {
    const {name, value, type, checked} = e.target;
    const updatedData = {
      ...data,
      [name]: type === 'checkbox' ? checked : value,
    };

    if (name === 'systemTest' && !checked) {
      updatedData.branchesWithoutTests = '';
      setBranchesError('');
    }

    setData(updatedData);

    if (name === 'supervisor') {
      validateName(value);
    } else if (name === 'taskCount') {
      if (!data.unknownTaskCount) {
        validateTaskCount(value);
      }
      if (data.systemTest && data.branchesWithoutTests.trim() !== '') {
        validateBranches(updatedData.branchesWithoutTests, value);
      }
    } else if (name === 'branchesWithoutTests') {
      if (data.systemTest) {
        validateBranches(value, updatedData.taskCount);
      }
    } else if (name === 'systemTest' && !checked) {
      setBranchesError('');
      if (data.unknownTaskCount) {
        setTaskCountError('');
      }
    } else if (name === 'unknownTaskCount') {
      if (checked) {
        setTaskCountError('');
        setData({
          ...updatedData,
          taskCount: '',
        });
      } else if (data.taskCount.trim() === '') {
        setTaskCountError('Количество задач обязательно для заполнения.');
      }
    }
  };

  const validateBranches = (branches, taskCount) => {
    if (!data.systemTest || branches.trim() === '') return;
    const regex = /^(\d{1,3})(,\s*\d{1,3})*$/;
    const branchesArray = branches.split(',').map((branch) => parseInt(branch.trim(), 10));
    if (!regex.test(branches) || branchesArray.some((branch) => isNaN(branch) || branch <= 0 || branch > taskCount)) {
      setBranchesError('Номера веток должны быть положительными целыми числами, разделенными запятой, ' +
        'и не превышать количество задач.');
    } else {
      setBranchesError('');
    }
  };

  const validateTaskCount = (count) => {
    const regex = /^[1-9][0-9]{0,2}$|^0$/;
    const parsedCount = parseInt(count, 10);

    if (!regex.test(count) || isNaN(parsedCount) || parsedCount < 0 || parsedCount > 100) {
      setTaskCountError('Количество задач должно быть целым числом от 0 до 100 и не должно начинаться с нуля.');
    } else {
      setTaskCountError('');
    }
  };

  const validateName = (name) => {
    const regex = /^[a-zA-Z0-9_\-.]{1,100}$/;
    if (!regex.test(name)) {
      setNameError('Название должно содержать символы латиницы ' +
        'верхнего и нижнего регистров, цифры, _, -, . и ' +
        'быть от 1 до 100 символов.');
    } else {
      setNameError('');
    }
  };

  const handleSave = () => {
    let isValid = true;

    if (data.supervisor.trim() === '') {
      setNameError('Название обязательно для заполнения.');
      isValid = false;
    } else {
      validateName(data.supervisor);
    }

    if (!data.unknownTaskCount && data.taskCount.trim() === '') {
      setTaskCountError('Количество задач обязательно для заполнения.');
      isValid = false;
    } else if (!data.unknownTaskCount) {
      validateTaskCount(data.taskCount);
    }

    if (data.systemTest && data.branchesWithoutTests.trim() === '') {
      setBranchesError('Номера веток обязательно для заполнения.');
      isValid = false;
    } else {
      validateBranches(data.branchesWithoutTests, data.taskCount);
    }

    if (!isValid || nameError || taskCountError || branchesError) {
      return;
    }

    onSave(data);
    setData({
      supervisor: '',
      taskCount: '',
      unknownTaskCount: false,
      systemTest: false,
      branchesWithoutTests: '',
    });
    setActive(false);
  };

  const handleBlur = (e) => {
    const {name, value} = e.target;

    if (value.trim() === '') {
      switch (name) {
        case 'supervisor':
          setNameError('Название обязательно для заполнения.');
          break;
        case 'taskCount':
          setTaskCountError('Количество задач обязательно для заполнения.');
          break;
        case 'branchesWithoutTests':
          if (data.systemTest) {
            setBranchesError('Номера веток обязательно для заполнения.');
          }
          break;
        default:
          break;
      }
    }
  };

  return (
    <div className=
      {active ? `modal active` : 'modal'}
    onClick={() => setActive(false)}>
      <div className={`modal__content ${data.systemTest ? 'small' : ''} ${
        nameError || taskCountError || branchesError ? 'extra-height' : ''
      }`}
      onClick={(e) => e.stopPropagation()}>
        <div className="modal-form">
          <h4 className="modal__title"> Новое домашнее задание </h4>
          <div className="form-field">
            <div className="d-flex align-items-center">
              <h4>Название</h4>
              <span className="form-star">*</span>
            </div>
            <input
              className={`modal-input ${nameError ? 'error' : ''}`}
              type="text"
              name="supervisor"
              value={data.supervisor}
              onChange={handleChange}
              placeholder="mapreduce"
              onBlur={handleBlur}
            />
            {nameError && <p className="error-message"
              style={{color: 'red', fontSize: '14px'}}>{nameError}</p>}
          </div>
          <div className="form-field">
            <div className="d-flex align-items-center">
              <h4>Количество задач</h4>
              <span className="form-star">*</span>
            </div>
            <input
              className={`modal-input ${taskCountError ? 'error' : ''}`}
              type="text"
              name="taskCount"
              value={data.taskCount}
              onChange={handleChange}
              placeholder="Например, 5"
              disabled={data.unknownTaskCount}
              onBlur={handleBlur}
            />
            {taskCountError &&
              <p className="error-message" style={{color: 'red', fontSize: '14px'}}>
                {taskCountError}
              </p>
            }
            <div className="checkbox d-flex align-items-center">
              <input
                id="unknownTaskCount"
                className="checkbox__input"
                type="checkbox"
                name="unknownTaskCount"
                checked={data.unknownTaskCount}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <label htmlFor="unknownTaskCount"
                className="checkbox__text">
                Я не знаю количество задач
              </label>
            </div>
            <div className="system-test d-flex align-items-center">
              <div className="d-flex align-items-center">
                <h4>Система тестирования</h4>
              </div>
              <div className="toggle">
                <input
                  className="toggle__input"
                  type="checkbox"
                  id="check"
                  name="systemTest"
                  checked={data.systemTest}
                  onChange={handleChange}
                />
                <label htmlFor="check" className="toggle__button"></label>
              </div>
            </div>
            {data.systemTest && (
              <div className="form-field">
                <div className="d-flex align-items-center">
                  <h4>Номера веток без тестов</h4>
                  <span className="form-star">*</span>
                </div>
                <input
                  className={`modal-input ${branchesError ? 'error' : ''}`}
                  type="text"
                  name="branchesWithoutTests"
                  value={data.branchesWithoutTests}
                  onChange={handleChange}
                  placeholder="Например: 1, 2, 3"
                  onBlur={handleBlur}
                />
                {branchesError &&
                  <p className="error-message" style={{color: 'red', fontSize: '14px'}}>
                    {branchesError}
                  </p>
                }
              </div>
            )}
          </div>
          <button type="button" className="modal__button" onClick={handleSave}>
            {taskToEdit ? 'Сохранить' : 'Добавить'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
