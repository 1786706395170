import React, {useEffect} from 'react';

import {Link, useNavigate} from 'react-router-dom';
import './mainPage.css';
import logo from '../../assets/logo-light.svg';

const checkTeacherStatus = () => {
  // return true if teacher is approved
  return true; // change after the logic
};

const MainPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Studoko';

    const isApproved = checkTeacherStatus();
    if (!isApproved) {
      navigate('/main/forbidden');
    }
  }, [navigate]);
  return (
    <div className="main-page" id="main-page">
      <div className="container">
        <div className="main">
          <img src={logo} className="main__img" alt="logo"/>
          <h1 className="main__title"> Studoko </h1>
          <p className="main__text">
            Проект кафедры АТП по централизации учебной работы
          </p>
          <Link to="/courses/register" className="main__button">
            Зарегистрировать курс
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
