// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.main__img {
    width: 400px;
    height: 400px;
    animation-name: rotation;
    animation-duration: 6s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.main__title {
    font-family: 'Play', sans-serif;
    font-weight: 400;
    font-size: 64px;
    color: #0A142F;
    margin-top: 30px;
}

.main__text {
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    margin: 30px 0 55px;
    font-weight: 600;
}

.main__button {
    border: none;
    cursor: pointer;
    color: #fff;
    background: #0A142F;
    border-radius: 8px;
    padding: 10px 0;
    margin-bottom: 20px;
    width: 370px;
    font-family: 'Lato', sans-serif;
}

.no-content {
    padding: 100px 0 25px;
}

.content-height {
    padding: 100px 0 35px;
}

.main__center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/main/mainPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,wBAAwB;IACxB,sBAAsB;IACtB,mCAAmC;IACnC,iCAAiC;AACrC;;AAEA;IACI,+BAA+B;IAC/B,gBAAgB;IAChB,eAAe;IACf,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,+BAA+B;IAC/B,eAAe;IACf,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,WAAW;IACX,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;IACf,mBAAmB;IACnB,YAAY;IACZ,+BAA+B;AACnC;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI;QACI,uBAAuB;IAC3B;IACA;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":[".main-page {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    text-align: center;\n}\n\n.main__img {\n    width: 400px;\n    height: 400px;\n    animation-name: rotation;\n    animation-duration: 6s;\n    animation-iteration-count: infinite;\n    animation-timing-function: linear;\n}\n\n.main__title {\n    font-family: 'Play', sans-serif;\n    font-weight: 400;\n    font-size: 64px;\n    color: #0A142F;\n    margin-top: 30px;\n}\n\n.main__text {\n    font-family: 'Lato', sans-serif;\n    font-size: 18px;\n    margin: 30px 0 55px;\n    font-weight: 600;\n}\n\n.main__button {\n    border: none;\n    cursor: pointer;\n    color: #fff;\n    background: #0A142F;\n    border-radius: 8px;\n    padding: 10px 0;\n    margin-bottom: 20px;\n    width: 370px;\n    font-family: 'Lato', sans-serif;\n}\n\n.no-content {\n    padding: 100px 0 25px;\n}\n\n.content-height {\n    padding: 100px 0 35px;\n}\n\n.main__center {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n}\n\n@keyframes rotation {\n    0% {\n        transform: rotate(0deg);\n    }\n    100% {\n        transform: rotate(360deg);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
