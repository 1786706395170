import './app.css';

import React from 'react';
import {
  Routes, Route, Navigate, BrowserRouter,
} from 'react-router-dom';

import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import MainPage from './components/main/MainPage';
import CourseRegistration from './pages/registration/CourseRegistration';
import Login from './pages/login/Login';
import SuccessPage from './pages/success/SuccessPage';
import {AuthProvider} from './context/AuthContext';
import ConfigPage from './pages/configPage/ConfigPage';

/**
 * The main App component for the React app.
 * @return {JSX.Element} The rendered App component.
 */
function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <div className="App">
          <Header/>
          <Routes>
            <Route path="/" element={<MainPage/>}/>
            <Route path="/main" element={<MainPage/>}/>
            <Route path="/courses/register" element={<CourseRegistration/>}/>
            <Route path="/login" element={<Login/>}/>
            <Route path="/courses/register/success" element={<SuccessPage/>}/>
            <Route path="/courses/register/config" element={<ConfigPage/>}/>
            { /* {errorRoutes.map((route, index) => (*/ }
            { /*  <Route key={index} {...route} />*/ }
            { /* ))}*/ }
            <Route path="*" element={<Navigate to="/error/servererror"/>}/>
          </Routes>
          <Footer/>
        </div>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
