import React, {useEffect, useRef, useState} from 'react';
import './configPage.css';
import '../registration/courseRegistration.css';

import download from '../../assets/download.svg';

const ConfigPage = () => {
  const [options] = useState([
    'Первый 1',
    'Второй 2',
    'Третий 3',
  ]);
  const [inputValue, setInputValue] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const dropdownRef = useRef(null);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setShowOptions(true);
  };

  const handleOptionClick = (value) => {
    setInputValue(value);
    setShowOptions(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowOptions(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="course">
      <div className="container">
        <div className="wrapper">
          <h2>Выгрузка конфигов</h2>
          <h3>Выберите учебный курс</h3>
          <div className="dropdown" ref={dropdownRef}>
            <input
              type="text"
              value={inputValue}
              onClick={() => setShowOptions(!showOptions)}
              onChange={handleInputChange}
              className="form-input form-select"
              placeholder="Начните вводить"
            />
            {showOptions && (
              <div className="dropdown-menu">
                {options
                    .filter((option) => option.toLowerCase().includes(inputValue.toLowerCase()))
                    .map((option, index) => (
                      <div
                        key={index}
                        className="dropdown-item"
                        onClick={() => handleOptionClick(option)}
                      >
                        {option}
                      </div>
                    ))}
              </div>
            )}
          </div>
          <button className="form-register d-flex align-items-center">
            <p>Сгенерировать и скачать конфиги</p>
            <img src={download} alt="download button"/>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfigPage;
