// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.course {
    font-family: 'Lato', sans-serif;
    padding: 140px 0;
}

.course h2 {
    text-align: center;
    margin-bottom: 100px;
    font-weight: 400;
    font-size: 28px;
}

.course h3 {
    font-weight: 500;
    font-size: 18px;
}

.wrapper h2, .wrapper h3 {
    color: #0A142F;
}

.course .form-input {
    border: 1px solid #E4E4E4;
    border-radius: 10px;
    font-family: 'Lato', sans-serif;
}
.form-input::placeholder {
    font-family: 'Lato', sans-serif;
    color: #B9B9B9;
    font-weight: 500;
}

.wrapper {
    width: 580px;
    margin: 0 auto;
}

.course .form-register {
    padding: 8px 92px;
    display: flex;
}

.course button img {
    padding: 0 0 5px 20px;
}
.course button p {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    display: contents;
}

.dropdown {
    position: relative;
    width: 100%;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid #E4E4E4;
    border-radius: 5px;
    background-color: white;
    z-index: 1000;
    max-height: 150px;
    overflow-y: auto;
}

.dropdown-item {
    padding: 10px;
    cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/pages/configPage/configPage.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAC/B,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,oBAAoB;IACpB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,+BAA+B;AACnC;AACA;IACI,+BAA+B;IAC/B,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,iBAAiB;IACjB,aAAa;AACjB;;AAEA;IACI,qBAAqB;AACzB;AACA;IACI,+BAA+B;IAC/B,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,OAAO;IACP,QAAQ;IACR,yBAAyB;IACzB,kBAAkB;IAClB,uBAAuB;IACvB,aAAa;IACb,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,eAAe;AACnB","sourcesContent":[".course {\n    font-family: 'Lato', sans-serif;\n    padding: 140px 0;\n}\n\n.course h2 {\n    text-align: center;\n    margin-bottom: 100px;\n    font-weight: 400;\n    font-size: 28px;\n}\n\n.course h3 {\n    font-weight: 500;\n    font-size: 18px;\n}\n\n.wrapper h2, .wrapper h3 {\n    color: #0A142F;\n}\n\n.course .form-input {\n    border: 1px solid #E4E4E4;\n    border-radius: 10px;\n    font-family: 'Lato', sans-serif;\n}\n.form-input::placeholder {\n    font-family: 'Lato', sans-serif;\n    color: #B9B9B9;\n    font-weight: 500;\n}\n\n.wrapper {\n    width: 580px;\n    margin: 0 auto;\n}\n\n.course .form-register {\n    padding: 8px 92px;\n    display: flex;\n}\n\n.course button img {\n    padding: 0 0 5px 20px;\n}\n.course button p {\n    font-family: 'Lato', sans-serif;\n    font-size: 16px;\n    display: contents;\n}\n\n.dropdown {\n    position: relative;\n    width: 100%;\n}\n\n.dropdown-menu {\n    position: absolute;\n    top: 100%;\n    left: 0;\n    right: 0;\n    border: 1px solid #E4E4E4;\n    border-radius: 5px;\n    background-color: white;\n    z-index: 1000;\n    max-height: 150px;\n    overflow-y: auto;\n}\n\n.dropdown-item {\n    padding: 10px;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
