import React from 'react';

import './footer.css';
import logo from '../../assets/telegram.svg';

const Footer = () => {
  return (
    <div className="footer" id="footer">
      <div className="container">
        <div className="content">
          <div className="footer__navbar">
            <ul className="navbar__menu d-flex align-items-center
                          justify-content-between">
              <li className="menu__info">
                <h3> Техподдержка </h3>
                <a href="mailto:mail.google.com"> studoko.system@gmail.com </a>
              </li>
              <li className="menu__info">
                <h3> Связаться с нами </h3>
                <a href="mailto:mail.yandex.ru"> atp@phystech.edu </a>
              </li>
              <li className="menu__info">
                <h3> Сдать домашку </h3>
                <a href="https://gitlab.atp-fivt.org"> gitlab.atp-fivt.org </a>
              </li>
              <li className="menu__info">
                <h3> Вики </h3>
                <a href="http://wiki.atp-fivt.org/index.php/Учебные_курсы_кафедры"> wiki.atp-fivt.org </a>
              </li>
              <li className="menu__info">
                <h3> Вакансии </h3>
                <div className="d-flex align-items-center">
                  <img className="menu__logo" src={logo} alt="Telegram logo"/>
                  <a href="https://t.me/atp_jobs"> @atp_jobs </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="rules d-flex">
          <p className="rules__atp">
            © 2024 АТП МФТИ
          </p>
          <p className="rules__text">
            Все права защищены
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
