// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    font-family: 'Lato', sans-serif;
    background: #0A142F;
    padding: 40px 0;
    color: #fff;
    margin-top: auto;
    width: 100%;
}

html, body {
    height: 100%;
    margin: 0;
}

.menu__info a {
    color: #b4abab;
}

.content {
    flex: 1 1;
}

.menu__logo {
    margin-right: 5px;
}

.menu__info h3 {
    font-weight: 500;
}

.rules__text {
    margin-left: auto;
}

.rules__text, .rules__atp {
    color: #b4abab;
}

.footer__navbar::after {
    content: '';
    display: block;
    width: 100%;
    margin: 40px 0;
    border-bottom: 1px solid #b4abab;
}
`, "",{"version":3,"sources":["webpack://./src/components/footer/footer.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAC/B,mBAAmB;IACnB,eAAe;IACf,WAAW;IACX,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,SAAS;AACb;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,SAAO;AACX;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,cAAc;IACd,WAAW;IACX,cAAc;IACd,gCAAgC;AACpC","sourcesContent":[".footer {\n    font-family: 'Lato', sans-serif;\n    background: #0A142F;\n    padding: 40px 0;\n    color: #fff;\n    margin-top: auto;\n    width: 100%;\n}\n\nhtml, body {\n    height: 100%;\n    margin: 0;\n}\n\n.menu__info a {\n    color: #b4abab;\n}\n\n.content {\n    flex: 1;\n}\n\n.menu__logo {\n    margin-right: 5px;\n}\n\n.menu__info h3 {\n    font-weight: 500;\n}\n\n.rules__text {\n    margin-left: auto;\n}\n\n.rules__text, .rules__atp {\n    color: #b4abab;\n}\n\n.footer__navbar::after {\n    content: '';\n    display: block;\n    width: 100%;\n    margin: 40px 0;\n    border-bottom: 1px solid #b4abab;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
