import React, {createContext, useEffect, useState} from 'react';
import {backendUrl} from '../constant/AppConstant';

/**
 * User data holder to embed in context
 */
export class UserData {
  /**
   * Full constructor
   * @param {string} firstName User first name
   * @param {string} lastName User last name
   * @param {string} patronymic User patronymic
   */
  constructor(firstName = '', lastName = '', patronymic = '') {
    this.firstName = firstName;
    this.lastName = lastName;
    this.patronymic = patronymic;
  }
}

const initialUserData = new UserData();

export const AuthContext = createContext({
  userData: initialUserData,
  setUserData: (value) => {},
});

export const AuthProvider = ({children}) => {
  const [userData, setUserData] = useState(initialUserData);

  useEffect(() => {
    const fetchUserData = async () => {
      console.log('useEffect running');
      try {
        const response = await fetch(`${backendUrl}/session/getUserInfo`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({}),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        console.log('Fetched user data:', data);

        const user = data.result;

        if (user) {
          setUserData(new UserData(user.firstName, user.lastName, user.patronymic));
          console.log('User data set:', user);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData().catch((error) => console.error('Failed to fetch user data:', error));
  }, []);


  return (
    <AuthContext.Provider value={{userData, setUserData}}>
      {children}
    </AuthContext.Provider>
  );
};
