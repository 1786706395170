import React, {useState} from 'react';
import './login.css';

import logo from '../../assets/logo-dark.svg';
import yandex from '../../assets/yandex.svg';
import google from '../../assets/google.svg';
import {backendUrl} from '../../constant/AppConstant';

const Login = () => {
  const [isTeacher, setIsTeacher] = useState(true);
  const handleYandexLogin = () => {
    const form = document.createElement('form');

    form.setAttribute('method', 'GET'); // Send as a GET request.
    form.setAttribute('action', `${backendUrl}/login/yandex`);

    document.body.appendChild(form);
    form.submit();
  };

  const handleGoogleLogin = () => {
    const form = document.createElement('form');

    form.setAttribute('method', 'GET'); // Send as a GET request.
    form.setAttribute('action', `${backendUrl}/login/google`);

    document.body.appendChild(form);
    form.submit();
  };
  const toggleRole = () => {
    setIsTeacher(!isTeacher);
  };

  return (
    <div className="login" id="login">
      <div className="container">
        <div className="block-login">
          <div className="studoko-logo">
            <img src={logo} alt="Studoko logo"/>
          </div>
          <h2 className="block-title"> Добро пожаловать в СтудОко! </h2>
          <div className="login-with">
            <h4 className="studoko-title">
              {isTeacher ? 'Войти как преподаватель' : 'Войти как студент'}
            </h4>
            <div className="search d-flex">
              <button className="search__block d-flex align-items-center"
                onClick={handleYandexLogin}>
                <img src={yandex} alt="Yandex"/>
                <p> Яндекс ID </p>
              </button>
              <button className="search__block right d-flex align-items-center"
                onClick={handleGoogleLogin}>
                <img src={google} alt="Google"/>
                <p> Google </p>
              </button>
            </div>
          </div>
          <button className="teacher" onClick={toggleRole}>
            {isTeacher ? 'Вы студент? Войти' : 'Вы преподаватель? Войти'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
