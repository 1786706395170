import './app.css';

import React from 'react';
import {
  Routes, Route, BrowserRouter,
} from 'react-router-dom';

import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import MainPage from './components/main/MainPage';
import Login from './pages/login/Login';
import SuccessPage from './pages/success/SuccessPage';
import {AuthProvider} from './context/AuthContext';
import ConfigPage from './pages/configPage/ConfigPage';
import ScrollToTop from './components/scrollToTop/scrollToTop';
import PrivateRoute from './components/privateRoute/privateRoute';
import CourseRegistration from './pages/registration/CourseRegistration';
import ErrorPage from './pages/errorPage/ErrorPage';

/**
 * The main App component for the React app.
 * @return {JSX.Element} The rendered App component.
 */
function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <div className="App">
          <Header/>
          <ScrollToTop/>
          <Routes>
            <Route path="/" element={<MainPage/>}/>
            <Route path="/main" element={<MainPage/>}/>
            <Route path="/login" element={<Login/>}/>
            <Route
              path="/courses/register"
              element={
                <PrivateRoute allowedRoles={['Teacher']}>
                  <CourseRegistration />
                </PrivateRoute>
              }
            />
            <Route
              path="/courses/register/success"
              element={
                <PrivateRoute allowedRoles={['Teacher']}>
                  <SuccessPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/courses/register/config"
              element={
                <PrivateRoute allowedRoles={['Teacher']}>
                  <ConfigPage />
                </PrivateRoute>
              }
            />
            <Route path="*" element={
              <ErrorPage errorCode={404} message={
                <>
                Кажется что-то пошло не так!
                Страница, которую вы запрашиваете, не существует. Возможно она <br/>
                устарела, была удалена, или был введен неверный адрес в адресной строке.
                </>
              } />} />
          </Routes>
          <Footer/>
        </div>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
