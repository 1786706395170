/**
 * Validates the data based on the configuration.
 * @param {Object} data - The data to be validated.
 * @param {Object} config - The validation configuration.
 * @return {Object} - Object containing errors.
 */
export function validator(data, config) {
  const errors={};
  /**
   * Validates the data based on the validation method.
   * @param {string} validateMethod - The validation method to be applied.
   * @param {any} data - The data to be validated.
   * @param {Object} config - The configuration for the validation method.
   * @return {string|undefined} - Error message
   * if validation fails, undefined otherwise.
   */
  function validate(validateMethod, data, config) {
    let statusValidate;
    let regex;
    // eslint-disable-next-line default-case
    switch (validateMethod) {
      case 'isRequired':
        statusValidate = data.trim() === '';
        break;
      case 'isFormatValid':
        regex = /^[а-яА-Яa-zA-Z0-9\-:_+., ]{1,255}$/;
        statusValidate = !regex.test(data);
        break;
      case 'isIdValid':
        regex = /^[a-zA-Z0-9_\-.]{1,100}$/;
        statusValidate = !regex.test(data);
        break;
      case 'isTableValid':
        regex = /^https:\/\/docs\.google\.com\/spreadsheets\/.+$/;
        statusValidate = !regex.test(data);
        break;
      case 'isNumberValid':
        regex = /^\d+$/;
        statusValidate = !regex.test(data);
        break;
      case 'isBranchNumberValid':
        regex = /^(\d{1,3})(,\s*\d{1,3})*$/;
        const taskCount = parseInt(config.taskCount);
        const branchesArray = data.split(',').map((branch) =>
          parseInt(branch.trim(), 10)).filter((branch) =>
          !isNaN(branch) && branch > 0 && branch <= taskCount);
        statusValidate = !regex.test(data) || branchesArray.length !== data.split(',').length;
        break;
    }
    if (statusValidate) return config.message;
  }
  // eslint-disable-next-line guard-for-in
  for (const fieldName in data) {
    // eslint-disable-next-line guard-for-in
    for (const validateMethod in config[fieldName]) {
      const error = validate(
          validateMethod, data[fieldName], config[fieldName][validateMethod]
      );
      if (error && !error[fieldName]) {
        errors[fieldName] = error;
      }
    }
  }
  return errors;
}
