// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-page {
    padding: 120px 0;
    margin: 0 auto;
    text-align: center;
}

.main__img {
    width: 400px;
    height: 400px;
    animation-name: rotation;
    animation-duration: 6s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.main__title {
    font-family: 'Play', sans-serif;
    font-weight: 400;
    font-size: 55px;

}

.main__text {
    font-family: 'Lato', sans-serif;
    font-size: larger;
    margin-bottom: 70px;
}

.main__button {
    border: none;
    cursor: pointer;
    color: #fff;
    background: #0A142F;
    border-radius: 9px;
    padding: 14px 153px;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}`, "",{"version":3,"sources":["webpack://./src/components/main/mainPage.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,wBAAwB;IACxB,sBAAsB;IACtB,mCAAmC;IACnC,iCAAiC;AACrC;;AAEA;IACI,+BAA+B;IAC/B,gBAAgB;IAChB,eAAe;;AAEnB;;AAEA;IACI,+BAA+B;IAC/B,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,WAAW;IACX,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI;QACI,uBAAuB;IAC3B;IACA;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":[".main-page {\n    padding: 120px 0;\n    margin: 0 auto;\n    text-align: center;\n}\n\n.main__img {\n    width: 400px;\n    height: 400px;\n    animation-name: rotation;\n    animation-duration: 6s;\n    animation-iteration-count: infinite;\n    animation-timing-function: linear;\n}\n\n.main__title {\n    font-family: 'Play', sans-serif;\n    font-weight: 400;\n    font-size: 55px;\n\n}\n\n.main__text {\n    font-family: 'Lato', sans-serif;\n    font-size: larger;\n    margin-bottom: 70px;\n}\n\n.main__button {\n    border: none;\n    cursor: pointer;\n    color: #fff;\n    background: #0A142F;\n    border-radius: 9px;\n    padding: 14px 153px;\n}\n\n@keyframes rotation {\n    0% {\n        transform: rotate(0deg);\n    }\n    100% {\n        transform: rotate(360deg);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
