import {React, useCallback, useEffect, useMemo, useState} from 'react';

import upload from '../../assets/upload.svg';
import edit from '../../assets/edit.svg';
import del from '../../assets/delete.svg';
import './courseRegistration.css';
import Modal from '../../modal/Modal';
import TextField from '../../components/textField/textField';
import {validator} from '../../utils/validator';


const CourseRegistration = () => {
  const [touchedFields] = useState({});
  const [modalActive, setModalActive] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [tasks, setTasks] = useState([]);

  const handleAddTask = (taskData) => {
    setTasks((prevTasks) => [...prevTasks, taskData]);
  };

  const handleDeleteTask = (index) => {
    setTasks((prevTasks) => prevTasks.filter((task, i) => i !== index));
  };
  const handleEditTask = (index) => {
    setEditIndex(index);
    setModalActive(true);
  };

  const handleSaveTask = (index, updatedTask) => {
    setTasks((prevTasks) =>
      prevTasks.map((task, i) =>
        (i === index ? {...task, ...updatedTask} : task)));
    setModalActive(false);
    setEditIndex(null);
  };

  const [assistants, setAssistants] = useState(['']);

  const addAssistant = () => {
    setAssistants([...assistants, '']);
  };

  const handleAssistantChange = (index, value) => {
    const updatedAssistants = [...assistants];
    updatedAssistants[index] = value;
    setAssistants(updatedAssistants);
  };

  const removeAssistant = (index) => {
    if (assistants.length === 1) {
      setAssistants(['']);
    } else {
      const updatedAssistants = assistants.filter((_, i) => i !== index);
      setAssistants(updatedAssistants);
    }
  };

  const [seminarists, setSeminarists] = useState(['']);
  const allSeminarists = ['Семинарист 1', 'Семинарист 2', 'Семинарист 3'];
  const allAssistants = ['Ассистент 1', 'Ассистент 2', 'Ассистент 3'];
  const getAvailableSeminarists = (index) => {
    return allSeminarists.filter(
        ((seminarist) => !seminarists.includes(seminarist) || seminarists[index] === seminarist)
    );
  };

  const getAvailableAssistants = (index) => {
    return allAssistants.filter(
        ((assistant) => !assistants.includes(assistant) || assistants[index] === assistant)
    );
  };
  const addSeminarist = () => {
    setSeminarists([...seminarists, '']);
  };

  const handleSeminaristChange = (index, value) => {
    const updatedSeminarists = [...seminarists];
    updatedSeminarists[index] = value;
    setSeminarists(updatedSeminarists);
  };

  const removeSeminarist = (index) => {
    if (seminarists.length === 1) {
      setSeminarists(['']);
    } else {
      const updatedSeminarists = seminarists.filter((_, i) => i !== index);
      setSeminarists(updatedSeminarists);
    }
  };
  const canAddSeminarist = seminarists.every((s) => s !== '') &&
    seminarists.filter((s) => s !== '').length < allSeminarists.length;
  const canAddAssistant = assistants.every((a) => a !== '') &&
    assistants.filter((a) => a !== '').length < allAssistants.length;
  // Validation field
  const [data, setData] = useState({name: '', id: '', table: ''});
  const [errors, setErrors] = useState({});
  const handleChange = ({target}) => {
    const {name, value} = target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Валидация на основе текущего значения
    if (value.trim() === '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: 'Это обязательное поле',
      }));
    } else {
      const fieldErrors = validateField(name, value);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: fieldErrors[name] || '',
      }));
    }
  };

  const validatorConfig = useMemo(() => ({
    name: {
      isRequired: {
        message: 'Название курса обязательно для заполнения',
      },
      isFormatValid: {
        message: 'Название курса должен содержать только ' +
          'буквы (кириллица и латиница ' +
          'верхнего и нижнего регистров), цифры, и ' +
          'символы -, :, _, +, . и иметь длину от 1 до 255 символов',
      },
    },
    id: {
      isRequired: {
        message: 'Идентификатор курса обязателен для заполнения',
      },
      isIdValid: {
        message: 'Идентификатор курса должен содержать только латинские ' +
          'символы верхнего и нижнего регистров, цифры, _, - и . и ' +
          'иметь длину от 1 до 100 символов',
      },
    },
    table: {
      isRequired: {
        message: 'Идентификатор курса обязателен для заполнения',
      },
      isTableValid: {
        message: 'Ссылка на таблицу должна начинаться с https://docs.google.com/spreadsheets/ ' +
          'и иметь длину не более 1024 символов',
      },
    },
    taskCount: {
      isRequired: {
        message: 'Поле обязательно для заполнения',
      },
      isNumberValid: {
        message: 'Количество задач должно быть числом',
      },
      isFormatValid: {
        message: 'Количество задач должно быть числом от 0 до 100',
      },
    },
    branchesWithoutTests: {
      isRequired: {
        message: 'Поле обязательно для заполнения',
      },
      isBranchNumberValid: {
        message: 'Номера веток должны быть положительными целыми числами, разделенными запятыми',
        taskCount: 100,
      },
    },
  }), []);

  const validate = useCallback((dataToValidate = data) => {
    const validationErrors = {};

    Object.keys(dataToValidate).forEach((field) => {
      if (dataToValidate[field].trim() === '') {
        validationErrors[field] = 'Это обязательное поле.';
      } else {
        const fieldErrors = validator({[field]: dataToValidate[field]}, validatorConfig);
        if (fieldErrors[field]) {
          validationErrors[field] = fieldErrors[field];
        }
      }
    });

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  }, [data, validatorConfig]);
  // Validation field
  useEffect(() => {
    if (Object.keys(touchedFields).length > 0) {
      validate();
    }
  }, [data, touchedFields, validate]);
  const [supervisor, setSupervisor] = useState('');
  // useEffect(() => {
  //   document.title = 'Registration course';
  //   window.scrollTo(0, 0);
  // }, []);
  // Validation checkbox
  const [serversTouched, setServersTouched] = useState(false);
  const [serversError, setServersError] = useState('');
  const handleCheckboxChange = () => {
    setServersTouched(true);
    const isChecked = checkIfAtLeastOneChecked();
    if (!isChecked) {
      setServersError('Выберите хотя бы один сервер');
    } else {
      setServersError('');
    }
  };
  const checkIfAtLeastOneChecked = () => {
    const checkboxes = document.querySelectorAll('.server-list input[type="checkbox"]');
    return Array.from(checkboxes).some((checkbox) => checkbox.checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setServersTouched(true);
    const isValid = validate();
    if (!isValid) {
      if (!checkIfAtLeastOneChecked()) {
        setServersError('Выберите хотя бы один сервер.');
      }
      return;
    }
  };
  const handleBlur = ({target: {name, value}}) => {
    if (value.trim() === '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: 'Это обязательное поле.',
      }));
    } else if (errors[name]) {
      const fieldErrors = validateField(name, value);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: fieldErrors[name] || '',
      }));
    }
  };
  const validateField = (name, value) => {
    const validationErrors = validator({[name]: value}, validatorConfig);
    return validationErrors;
  };
  return (
    <div className="registration" id="registration">
      <div className="container">
        <div className="register-form-container">
          <h1 className="form-title">
            Регистрация курса
          </h1>
          <form onSubmit={handleSubmit} className="form-filds">
            <div className="form-field">
              <div className="d-flex align-items-center">
                <h4>Полное название курса</h4>
                <span className="form-star">*</span>
              </div>
              <TextField
                type="text"
                id="name"
                name="name"
                value={data.name}
                onChange={handleChange}
                placeholder="Параллельные алгоритмы"
                error={errors.name}
                onBlur={handleBlur}
              />
            </div>
            <div className="form-field">
              <div className="d-flex align-items-center">
                <h4>Идентификатор курса</h4>
                <span className="form-star">*</span>
              </div>
              <TextField
                type="text"
                id="id"
                name="id"
                value={data.id}
                onChange={handleChange}
                placeholder="my-iDENTifier.123"
                error={errors.id}
                onBlur={handleBlur}
              />
            </div>
            <div className="form-field">
              <div className="d-flex align-items-center">
                <h4>Руководитель курса</h4>
                <span className="form-star">*</span>
              </div>
            </div>
            <select
              className="form-input form-select"
              value={supervisor}
              onChange={(e) => setSupervisor(e.target.value)}
            >
              <option value="">Выберите руководителя</option>
              <option value="Колмогоров Александр Алексеевич,
              kolmogorov.aa@phystech.edu">
                Колмогоров Александр Алексеевич
              </option>
              <option value="Фартыгин Артем,
              temikfart.aa@phystech.edu">
                Фартыгин Артем
              </option>
              <option value="Суворов Алексей,
              suvorov.aa@phystech.edu">
                Суворов Алексей
              </option>
              <option value="Якушкова Елизавета,
              iakushkova.aa@phystech.edu">
                Якушкова Елизавета
              </option>
              <option value="Кошелев Александр,
              koshelev.aa@phystech.edu">
                Кошелев Александр
              </option>
            </select>
            <div className="form-field">
              <div className="d-flex align-items-center">
                <h4>Семинаристы курса</h4>
              </div>
              {seminarists.map((seminarist, index) => (
                <div key={index} className="d-flex align-items-center">
                  <select
                    className="form-input form-select"
                    value={seminarist}
                    onChange={(e) => handleSeminaristChange(index, e.target.value)}
                  >
                    <option value="">Выберите семинариста</option>
                    {getAvailableSeminarists(index).map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  {(seminarists.length > 1 || seminarist) && (
                    <button
                      type="button"
                      className="delete name-delete"
                      onClick={() => removeSeminarist(index)}
                    >
                      <img src={del} alt="Удалить" />
                    </button>
                  )}
                </div>
              ))}
              <button
                type="button"
                className="form-plus"
                onClick={addSeminarist}
                disabled={!canAddSeminarist}
              >
                +
              </button>
            </div>
            <div className="form-field">
              <div className="d-flex align-items-center">
                <h4>Ассистенты курса</h4>
              </div>
              {assistants.map((assistant, index) => (
                <div key={index} className="d-flex align-items-center">
                  <select
                    className="form-input form-select"
                    value={assistant}
                    onChange={(e) => handleAssistantChange(index, e.target.value)}
                  >
                    <option value="">Выберите ассистента</option>
                    {getAvailableAssistants(index).map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  {(assistants.length > 1 || assistant) && (
                    <button
                      type="button"
                      className="delete name-delete"
                      onClick={() => removeAssistant(index)}
                    >
                      <img src={del} alt="Удалить" />
                    </button>
                  )}
                </div>
              ))}
              <button
                type="button"
                className="form-plus"
                onClick={addAssistant}
                disabled={!canAddAssistant}
              >
                +
              </button>
            </div>
            <div className="form-field">
              <div className="d-flex align-items-center">
                <h4>Ссылка на таблицу курса</h4>
                <span className="form-star">*</span>
              </div>
              <div className="form-indent">
                <p>В таблице должно быть минимум 2 вкладки:</p>
                <ul>
                  <li>Список студентов</li>
                  <li>Оценки студентов</li>
                </ul>
                <p>К таблице нужно выдать доступ на 2 email'а:</p>
                <ul>
                  <li>servers@atp-fivt.org</li>
                  <li>gsheets@automation-robot-1.iam.gserviceaccount.com</li>
                </ul>
              </div>
              <TextField
                type="text"
                id="table"
                name="table"
                value={data.table}
                onChange={handleChange}
                placeholder="https://docs.google.com/spreadsheets/d/example"
                error={errors.table}
                onBlur={handleBlur}
              />
            </div>
            <div className="form-field">
              <div className="d-flex align-items-center">
                <h4>Конфигурирование домашних заданий</h4>
                <span className="form-star">*</span>
              </div>
              {tasks.length >= 0 && (
                <div className="table-container">
                  <table>
                    <thead>
                      <tr>
                        <th>Название</th>
                        <th>Количество задач</th>
                        <th>Тестирование</th>
                        <th>Номера веток без тестов</th>
                        <th>Действия</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tasks.map((task, index) => (
                        <tr key={index}>
                          <td>{task.supervisor}</td>
                          <td>{task.unknownTaskCount ? 'N/A' :
                            task.taskCount}</td>
                          <td>{task.systemTest ? 'Да' : 'Нет'}</td>
                          <td>{task.branchesWithoutTests}</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <button type="button" className="edit"
                                onClick={() => handleEditTask(index)}>
                                <img src={edit} alt="Редактировать"/>
                              </button>
                              <button type="button" className="delete"
                                onClick={() => handleDeleteTask(index)}>
                                <img src={del} alt="Удалить"/>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
              <button type="button" className="form-plus form-modal"
                onClick={() => setModalActive(true)}>
                +
              </button>
              <Modal
                active={modalActive}
                setActive={setModalActive}
                onAdd={handleAddTask}
                onSave={(taskData) => {
                  if (editIndex !== null) {
                    handleSaveTask(editIndex, taskData);
                  } else {
                    handleAddTask(taskData);
                  }
                }}
                taskToEdit={editIndex !== null ? tasks[editIndex] : null}
              />
            </div>
            <div className="form-field form-margin">
              <div className="d-flex align-items-center">
                <h4>Необходимые сервера</h4>
                <span className="form-star">*</span>
              </div>
              <p> Отметьте галочкой нужные для курса сервера.
                Характеристики каждого<br/> сервера - в кружочке с вопросом.
              </p>
              <ul className="server-list">
                <li className="server-item d-flex align-items-center">
                  <label className="server-name">
                    <input type="checkbox" onChange={handleCheckboxChange}/>
                  </label>
                  <p>servername</p>
                  <span
                    className=
                      {modalActive ? 'server-characteristics none' :
                        'server-characteristics'}
                    title="Здесь описание для подсказки">?
                  </span>
                </li>
                <li className="server-item d-flex align-items-center">
                  <label className="server-name">
                    <input type="checkbox" onChange={handleCheckboxChange}/>
                  </label>
                  <p>servername</p>
                  <span
                    className=
                      {modalActive ? 'server-characteristics none' :
                        'server-characteristics'}
                    title="Здесь описание для подсказки">?
                  </span>
                </li>
                <li className="server-item d-flex align-items-center">
                  <label className="server-name">
                    <input type="checkbox" onChange={handleCheckboxChange}/>
                  </label>
                  <p>servername</p>
                  <span
                    className=
                      {modalActive ? 'server-characteristics none' :
                        'server-characteristics'}
                    title="Здесь описание для подсказки">?
                  </span>
                </li>
                <li className="server-item d-flex align-items-center">
                  <label className="server-name">
                    <input type="checkbox" onChange={handleCheckboxChange}/>
                  </label>
                  <p>servername</p>
                  <span
                    className=
                      {modalActive ? 'server-characteristics none' :
                        'server-characteristics'}
                    title="Здесь описание для подсказки">?
                  </span>
                </li>
                <li className="server-item d-flex align-items-center">
                  <label className="server-name">
                    <input type="checkbox" onChange={handleCheckboxChange}/>
                  </label>
                  <p>servername</p>
                  <span
                    className=
                      {modalActive ? 'server-characteristics none' :
                        'server-characteristics'}
                    title="Здесь описание для подсказки">?
                  </span>
                </li>
              </ul>
              {serversTouched && serversError && <p className="form-error">{serversError}</p>}
            </div>
            <div className="form-field">
              <div>
                <h4>Специализированный CI и Dockerfile</h4>
              </div>
              <div className="form-indent">
                <p>Прикрепите архив из следующих файлов:</p>
                <ul>
                  <li>Если нужен CI, то добавьте gitlab-ci.yml файлы для
                    каждого<br/> репозитория;
                  </li>
                  <li>Если нужен специальный Docker контейнер, то добавьте
                    исходный<br/> Dockerfile с нужными данными.
                  </li>
                </ul>
              </div>
              <button type="button" className="form-plus">
                <img src={upload} alt="Upload img"/>
              </button>
            </div>
            <button type="submit" className="form-register">
              Зарегистрировать курс
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CourseRegistration;
